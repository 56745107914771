import styled from "styled-components";
import { breakpoint } from "@boxt/design-system";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${breakpoint("md")} {
    flex-direction: row;
  }
`;
