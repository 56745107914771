import useTranslation from "next-translate/useTranslation";
import { Paragraph } from "@boxt/design-system";

import Image from "@Components/Image";

import { Container, ContentWrapper, IconWrapper, ImageContainer, ListItem } from "./styles";
import type { Props } from "./types";

export const ButterPdpValuePropBanner = ({ fields, i18nNamespace, testId }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { main_icon, main_icon_alt, content } = fields;

  return (
    <Container data-testid={testId}>
      <IconWrapper>
        <ImageContainer>
          <Image
            alt={t("value-prop-banner.main-icon-alt", { main_icon_alt })}
            height={48}
            src={main_icon}
            width={108}
          />
        </ImageContainer>
      </IconWrapper>

      <ContentWrapper>
        {content.map(({ icon, value_prop_copy }) => (
          <ListItem key={value_prop_copy}>
            <Image alt="value prop icon" height={24} src={icon} width={24} />
            <Paragraph boxtTheme="slate" weight="regular">
              {t("value-prop-banner.value-prop-copy", { value_prop_copy })}
            </Paragraph>
          </ListItem>
        ))}
      </ContentWrapper>
    </Container>
  );
};
