import styled, { css } from "styled-components";
import { isEmpty } from "lodash-es";
import tinycolor from "tinycolor2";
import { breakpoint, colors, Grid, rem } from "@boxt/design-system";

import type { BackgroundGradient, ButterLandingHeroGradient } from "./types";

const getBackgroundGradient = (
  gradientType: BackgroundGradient | undefined,
  gradients: ButterLandingHeroGradient[] | undefined = [],
  gradientDegree?: number,
  gradientCenterXAxis?: number,
  gradientCenterYAxis?: number,
) => {
  if (isEmpty(gradients) || gradients?.length <= 1) {
    return;
  }

  const gradientColours = gradients
    .map(
      ({ colour, stop, opacity }) =>
        `${tinycolor(colour || colors.coral.normal)
          .setAlpha(opacity || 1)
          .toRgbString()} ${stop || 0}%`,
    )
    .join(", ");

  if (gradientType === "linear-gradient") {
    return css`
      background: linear-gradient(${gradientDegree || 90}deg, ${gradientColours});
    `;
  } else {
    return css`
      background: radial-gradient(
        circle at ${gradientCenterXAxis || 50}% ${gradientCenterYAxis || 50}%,
        ${gradientColours}
      );
    `;
  }
};

export const Container = styled.div<{
  $backgroundColor?: string;
  $gradientType?: BackgroundGradient;
  $gradients?: ButterLandingHeroGradient[];
  $gradientDegree?: number;
  $gradientCenterXAxis?: number;
  $gradientCenterYAxis?: number;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  margin-top: -${rem(56)};
  position: relative;

  ${({ $gradientType, $gradients, $gradientDegree, $gradientCenterXAxis, $gradientCenterYAxis }) =>
    getBackgroundGradient($gradientType, $gradients, $gradientDegree, $gradientCenterXAxis, $gradientCenterYAxis)};

  ${breakpoint("md")} {
    margin-top: -${rem(61)};
  }

  ${breakpoint("lg")} {
    min-height: ${rem(800)};
    margin-top: -${rem(76)};
  }
`;

export const InnerContainer = styled.div`
  padding-bottom: ${rem(16)};
  position: relative;
  max-width: ${rem(1584)};
  margin: 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;

  ${breakpoint("md")} {
    padding-bottom: ${rem(32)};
  }

  ${breakpoint("lg")} {
    padding-bottom: ${rem(75)};
  }
`;

export const StyledGrid = styled(Grid)`
  position: relative;
  width: 100%;
  margin-top: ${rem(-32)};

  ${breakpoint("lg")} {
    margin-top: ${rem(152)};
  }
`;

export const ForegroundImageContainer = styled.div<{
  $hasForegroundImage: boolean;
  $hasBackgroundVideo: boolean;
}>`
  min-height: ${({ $hasForegroundImage }) => ($hasForegroundImage ? "auto" : rem(330))};
  position: relative;

  ${breakpoint("md")} {
    min-height: ${({ $hasForegroundImage }) => ($hasForegroundImage ? "auto" : rem(453))};
  }

  ${breakpoint("lg")} {
    height: ${rem(730)};
    position: absolute;
    width: 75%;
    left: ${({ $hasForegroundImage }) => ($hasForegroundImage ? rem(40) : 0)};
  }
`;
