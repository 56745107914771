import { type RefObject } from "react";

import BottomBar from "@Components/pages/common/ButterLandingHero/components/BottomBar";

import ArrowDown from "./components/ArrowDown";
import BackgroundAnimation from "./components/BackgroundAnimation";
import BackgroundImage from "./components/BackgroundImage";
import BackgroundVideo from "./components/BackgroundVideo";
import Card from "./components/Card";
import ForegroundImages from "./components/ForegroundImages";
import ForegroundVideo from "./components/ForegroundVideo";
import TvAdvertBadge from "./components/TvAdvertBadge";
import { Container, ForegroundImageContainer, InnerContainer, StyledGrid } from "./styles";
import type { ButterLandingHeroFields } from "./types";

export type Props = {
  contentPageRef?: RefObject<HTMLDivElement | null>;
  fields: ButterLandingHeroFields;
  cardsRef?: RefObject<HTMLDivElement | null>;
};

const ButterLandingHero = ({ contentPageRef, fields, cardsRef }: Props) => {
  const hasForegroundImage = fields.foreground_images ? fields.foreground_images?.length > 0 : false;
  const hasBackgroundImage =
    Boolean(fields.background_image_desktop) &&
    Boolean(fields.background_image_tablet) &&
    Boolean(fields.background_image_mobile);
  const hasBackgroundAnimation = Boolean(fields.background_animation);
  const hasBadge = Boolean(fields.badge);
  const hasTvAdvertBadge = Boolean(fields.advert_badge_video_id);
  const isForegroundImageMaxWidth = Boolean(fields.max_width_foreground_image);
  const hasArrowDown = Boolean(fields.show_arrow_down);
  const hasBottomBar = Boolean(fields.show_bottom_bar);
  const hasBackgroundVideo = Boolean(fields.background_video) && Boolean(fields.background_video_mobile);
  const hasForegroundVideo = fields.is_foreground_video;

  return (
    <>
      <Container
        $backgroundColor={fields.background_colour}
        $gradientCenterXAxis={fields.background_gradient_center_x_axis}
        $gradientCenterYAxis={fields.background_gradient_center_y_axis}
        $gradientDegree={fields.background_linear_gradient_degree}
        $gradients={fields.background_gradients}
        $gradientType={fields.background_gradient}
        data-testid="landing-hero-container"
        id={fields.id}
      >
        {hasBackgroundImage ? (
          <BackgroundImage
            backgroundImage={{
              desktop: fields.background_image_desktop,
              mobile: fields.background_image_mobile,
              tablet: fields.background_image_tablet,
            }}
          />
        ) : null}
        {hasBackgroundAnimation ? <BackgroundAnimation animation={fields.background_animation} /> : null}
        {isForegroundImageMaxWidth && !hasBackgroundVideo ? (
          <ForegroundImages
            fields={fields}
            hasBadge={hasBadge}
            hasForegroundImage={hasForegroundImage}
            isForegroundImageMaxWidth={isForegroundImageMaxWidth}
          />
        ) : null}
        {hasBackgroundVideo && !hasForegroundVideo ? (
          <BackgroundVideo
            backgroundPoster={fields.background_video_poster}
            backgroundVideo={fields.background_video}
            hasForegroundImage={hasForegroundImage}
            isHomePage={fields.hero_variant === "homepage"}
            isVideoLooping={fields.background_video_loop}
            mobileBackgroundPoster={fields.background_video_mobile_poster}
            mobileBackgroundVideo={fields.background_video_mobile}
            videoDescription={fields.background_video_description}
          />
        ) : null}
        <InnerContainer>
          {!isForegroundImageMaxWidth && hasForegroundImage && !hasForegroundVideo ? (
            <ForegroundImageContainer
              $hasBackgroundVideo={hasBackgroundVideo}
              $hasForegroundImage={hasForegroundImage}
              id={fields?.carousel_id}
            >
              <ForegroundImages
                fields={fields}
                hasBadge={hasBadge}
                hasForegroundImage={hasForegroundImage}
                isForegroundImageMaxWidth={isForegroundImageMaxWidth}
              />
            </ForegroundImageContainer>
          ) : null}
          {hasForegroundVideo ? (
            <ForegroundVideo
              isVideoLooping={fields.background_video_loop}
              mobilePoster={fields.background_video_mobile_poster}
              mobileVideo={fields.background_video_mobile}
              video={fields.background_video}
              videoDescription={fields.background_video_description}
              videoPoster={fields.background_video_poster}
            />
          ) : null}
          {hasTvAdvertBadge ? (
            <TvAdvertBadge
              advertBadgeText={fields.advert_badge_text}
              advertBadgeVideoId={fields.advert_badge_video_id as string}
            />
          ) : null}
          <StyledGrid>
            <Card
              backgroundColor={fields.card_background_colour}
              bottomSheetTiles={fields.get_a_quote_bottom_sheet_tiles}
              callToActionButtons={fields.call_to_action_buttons}
              cardId={fields.card_id}
              finishQuoteButtonSkin={fields.finish_quote_button_skin}
              finishQuoteButtonText={fields.finish_quote_button_text}
              finishQuoteButtonTheme={fields.finish_quote_button_theme}
              heading={fields.card_heading}
              headingTheme={fields.card_heading_colour}
              heroVariant={fields.hero_variant}
              isCardLeftAligned={fields.left_aligned_card}
              isTextWrapBalanced={fields.text_wrap_balanced}
              label={fields.card_label}
              labelTheme={fields.card_label_colour}
              mainStartQuoteButtonSkin={fields.main_start_quote_button_skin}
              mainStartQuoteButtonText={fields.main_start_quote_button_text}
              mainStartQuoteButtonTheme={fields.main_start_quote_button_theme}
              screenerVariant={fields.product_screening_variant}
              scrollButton={fields.scroll_button}
              scrollTargetRef={cardsRef}
              startQuoteButtonSkin={fields.start_quote_button_skin}
              startQuoteButtonText={fields.start_quote_button_text}
              startQuoteButtonTheme={fields.start_quote_button_theme}
              subheading={fields.card_subheading}
              subheadingTheme={fields.card_subheading_colour}
              thumbnail={fields.card_thumbnail}
              thumbnailAltText={fields.card_thumbnail_alt_text}
              uspBadges={fields.usp_badges}
              uspBulletPoints={fields.usp_bullet_points}
            />
          </StyledGrid>
        </InnerContainer>
        {hasArrowDown ? <ArrowDown contentPageRef={contentPageRef} /> : null}
      </Container>
      {hasBottomBar ? <BottomBar heading={fields?.card_heading} subheading={fields.card_subheading} /> : null}
    </>
  );
};

export default ButterLandingHero;
