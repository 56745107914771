import Image from "@Components/Image";

import { BadgeContainer } from "./styles";

type Props = {
  badge: string;
  badgeAltText: string;
};

const Badge = ({ badge, badgeAltText }: Props) => (
  <BadgeContainer>
    <Image alt={badgeAltText} layout="fill" objectFit="cover" src={badge} />
  </BadgeContainer>
);

export default Badge;
