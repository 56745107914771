"use client";

import styled from "styled-components";
import { colors } from "@boxt/design-system";

export const BogofContainer = styled.div`
  border: 1px solid ${colors.coral.lighter};
  background-color: ${colors.coral.lightest};
  border-radius: 4px;
  overflow: hidden;
`;
