import styled from "styled-components";
import { breakpoint, Button, colors, Paragraph, rem } from "@boxt/design-system";

export const CardContainer = styled.div<{ $desktopGridLayout: string; $tabletGridLayout: string }>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: ${rem(16)};
  max-width: ${rem(1440)};
  height: 100%;

  flex-direction: column;
  background-color: ${colors.white};
  padding: 0;
  margin: auto;

  ${breakpoint("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: ${({ $tabletGridLayout }) => $tabletGridLayout};
  }

  ${breakpoint("lg")} {
    margin: 0 ${rem(100)};
    grid-template-areas: ${({ $desktopGridLayout }) => $desktopGridLayout};
    gap: ${rem(32)};
  }
`;

export const CardContent = styled.div<{ $isDoubleCard?: boolean }>`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${rem(8)} ${rem(16)} ${rem(24)} ${rem(16)};
  flex: 1;

  ${breakpoint("lg")} {
    padding: ${({ $isDoubleCard }) =>
      $isDoubleCard ? `0 ${rem(32)} ${rem(32)} ${rem(32)}` : `${rem(24)} 0 ${rem(32)} ${rem(32)}`};
    width: ${({ $isDoubleCard }) => ($isDoubleCard ? "100%" : "53%")};
  }
`;

export const Container = styled.div<{ $isDoubleCard?: boolean }>`
  overflow: hidden;
  height: auto;
  width: 100%;
  padding: ${({ $isDoubleCard }) =>
    $isDoubleCard ? `0 ${rem(16)} ${rem(8)} ${rem(16)}` : `${rem(16)} ${rem(16)} ${rem(8)} ${rem(16)}`};
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint("md")} {
    padding: 0 ${rem(16)} ${rem(8)} ${rem(16)};
    margin-bottom: auto;
  }

  ${breakpoint("lg")} {
    padding: 0;
    margin: auto;
    width: ${({ $isDoubleCard }) => ($isDoubleCard ? "100%" : "47%")};
  }
`;

export const ButtonContainer = styled.div`
  padding-top: ${rem(16)};
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  gap: ${rem(16)};
`;

export const StyledButton = styled(Button)`
  border-radius: 2px;
  width: fit-content;
`;

export const ImageDiv = styled.div<{ $isDoubleCard?: boolean; $isButtonHovered: boolean }>`
  position: relative;
  min-height: ${({ $isDoubleCard }) => ($isDoubleCard ? `${rem(240)}` : `${rem(188)}`)};
  max-width: ${({ $isDoubleCard }) => ($isDoubleCard ? `${rem(240)}` : `${rem(188)}`)};
  width: 100%;
  transition: transform 300ms ease-in-out;

  ${breakpoint("lg")} {
    transform: ${({ $isButtonHovered }) => $isButtonHovered && `scale(1.05);`};
    min-height: ${({ $isDoubleCard }) => ($isDoubleCard ? `${rem(320)}` : `${rem(220)}`)};
    max-width: ${({ $isDoubleCard }) => ($isDoubleCard ? `${rem(320)}` : `${rem(267)}`)};
  }
`;

export const Card = styled.div<{
  $backgroundTheme?: string;
  $hoverTheme?: string;
  $backgroundGradientStartColour?: string;
  $backgroundGradientEndColour?: string;
  $isDoubleCard?: boolean;
  $isButtonHovered: boolean;
  $gridArea: string;
}>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  background: ${({ $backgroundTheme, $backgroundGradientStartColour, $backgroundGradientEndColour }) =>
    $backgroundTheme ||
    ($backgroundGradientStartColour &&
      `linear-gradient(to bottom right, ${$backgroundGradientStartColour}, ${$backgroundGradientEndColour})`) ||
    `${colors.slate.lightest}`};
  border-radius: 8px;

  ${breakpoint("md")} {
    grid-area: ${({ $gridArea }) => $gridArea};
  }

  ${breakpoint("lg")} {
    flex-direction: ${({ $isDoubleCard }) => !$isDoubleCard && "row"};
    min-height: ${({ $isDoubleCard }) => ($isDoubleCard ? `${rem(660)}` : `${rem(330)}`)};
    background: ${({
      $backgroundTheme,
      $backgroundGradientStartColour,
      $backgroundGradientEndColour,
      $isButtonHovered,
      $hoverTheme,
    }) =>
      ($isButtonHovered && $hoverTheme) ||
      $backgroundTheme ||
      ($backgroundGradientStartColour &&
        `linear-gradient(to bottom right, ${$backgroundGradientStartColour}, ${$backgroundGradientEndColour})`) ||
      `${colors.slate.lightest}`};
  }
`;

export const StyledParagraph = styled(Paragraph)<{ $isDoubleCard?: boolean }>`
  max-height: ${({ $isDoubleCard }) => ($isDoubleCard ? `${rem(520)}` : `unset`)};
`;
