import styled, { css } from "styled-components";
import { isEmpty } from "lodash-es";
import tinyColor from "tinycolor2";
import { breakpoint, colors, Grid, rem } from "@boxt/design-system";

import Image from "@Components/Image";

import type { BackgroundGradient, ButterLandingHeroGradient } from "../ButterLandingHero/types";

import type { Theme } from "./types";

const getThemeColor = (theme: Theme): { background: string; border: string } => {
  switch (theme) {
    case "acid":
      return { background: colors.acidGreen.lighter, border: colors.acidGreen.darker };
    case "slate":
      return { background: colors.slate.lightest, border: colors.slate.lighter };
  }
};

/**
 * returns relative background property for either linear-gradient or radial-gradient as set in ButterCMS
 */
const getBackgroundGradient = (
  gradientType: BackgroundGradient | undefined,
  gradients: ButterLandingHeroGradient[] | undefined = [],
  gradientDegree?: number,
  gradientCenterXAxis?: number,
  gradientCenterYAxis?: number,
) => {
  if (isEmpty(gradients) || gradients?.length <= 1) {
    return;
  }

  const gradientColours = gradients
    .map(
      ({ colour, stop, opacity }) =>
        `${tinyColor(colour || colors.coral.normal)
          .setAlpha(opacity || 1)
          .toRgbString()} ${stop || 0}%`,
    )
    .join(", ");

  if (gradientType === "linear-gradient") {
    return css`
      background: linear-gradient(${gradientDegree || 90}deg, ${gradientColours});
    `;
  }
  return css`
    background: radial-gradient(
      circle at ${gradientCenterXAxis || 50}% ${gradientCenterYAxis || 50}%,
      ${gradientColours}
    );
  `;
};

export const Container = styled.div<{
  $backgroundColor?: string;
  $gradientType?: BackgroundGradient;
  $gradients?: ButterLandingHeroGradient[];
  $gradientDegree?: number;
  $gradientCenterXAxis?: number;
  $gradientCenterYAxis?: number;
}>`
  position: relative;
  overflow: hidden;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  background-color: ${({ $backgroundColor }) => $backgroundColor};

  ${({ $gradientType, $gradients, $gradientDegree, $gradientCenterXAxis, $gradientCenterYAxis }) =>
    getBackgroundGradient($gradientType, $gradients, $gradientDegree, $gradientCenterXAxis, $gradientCenterYAxis)};
`;

export const StyledGrid = styled(Grid)`
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;

  ${breakpoint("md")} {
    padding-top: ${rem(48)};
  }
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
`;

export const GridContainer = styled.div`
  background-color: ${colors.white};
  max-width: ${rem(848)};
  border-radius: ${rem(16)};
  margin-bottom: ${rem(32)};
  margin-inline: 0;

  ${breakpoint("md")} {
    padding-bottom: ${rem(32)};
  }
`;

export const HeadingWrapper = styled.div`
  padding: ${rem(48)} ${rem(16)} ${rem(32)};
`;

export const ImageCol = styled.div<{ $theme: Theme }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(8)};

  ${breakpoint("md")} {
    padding: ${rem(16)};
    border-top-left-radius: ${rem(20)};
    border-top-right-radius: ${rem(20)};
    ${({ $theme }) => css`
      background: ${getThemeColor($theme).background};
      border: 2px solid ${getThemeColor($theme).border};
    `}
  }
`;

export const RowWrapper = styled(Grid.Row)`
  margin: 0 ${rem(8)};

  &:nth-child(3) > div:nth-child(2) {
    border-top: 2px solid ${colors.acidGreen.darker};
    border-top-left-radius: ${rem(20)};
    border-top-right-radius: ${rem(20)};

    ${breakpoint("md")} {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &:nth-child(3) > div:nth-child(3) {
    border-top: 2px solid ${colors.slate.lighter};
    border-top-left-radius: ${rem(20)};
    border-top-right-radius: ${rem(20)};

    ${breakpoint("md")} {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  ${breakpoint("lg")} {
    gap: ${rem(16)};
  }
`;

export const Question = styled.div<{ $isLastRow: boolean; $hasTooltip: boolean }>`
  display: flex;
  align-items: center;
  padding-top: ${rem(8)};
  padding-bottom: ${rem(8)};
  gap: ${rem(8)};
  border-bottom: 1px dashed ${tinyColor(colors.slate.lighter).setAlpha(0.6).toString()};

  ${({ $isLastRow }) =>
    $isLastRow &&
    css`
      border-bottom: none;
    `}

  ${({ $hasTooltip }) =>
    $hasTooltip &&
    css`
      ~ div {
        align-items: flex-start;
        ${breakpoint("md")} {
          align-items: center;
        }
      }
    `}
`;

export const AnswerCol = styled.div<{ $theme: Theme; $isLastRow: boolean; $hasTooltip: boolean }>`
  min-height: ${rem(52)};
  display: flex;
  gap: ${rem(4)};
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: ${rem(8)};
  padding-bottom: ${rem(8)};
  border-bottom: 1px dashed ${tinyColor(colors.slate.lighter).setAlpha(0.6).toString()};

  > p {
    font-size: ${rem(14)};
  }

  ${({ $theme }) => css`
    background: ${getThemeColor($theme).background};
    border-left: 2px solid ${getThemeColor($theme).border};
    border-right: 2px solid ${getThemeColor($theme).border};
  `}

  ${({ $theme, $isLastRow }) =>
    $isLastRow &&
    css`
      border-bottom: 2px solid ${getThemeColor($theme).border};
      border-bottom-left-radius: ${rem(20)};
      border-bottom-right-radius: ${rem(20)};
    `}

  ${breakpoint("md")} {
    min-height: ${rem(64)};
    font-size: ${rem(16)};
    gap: ${rem(8)};

    > p {
      font-size: ${rem(16)};
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(20)};
`;

export const TooltipWrapper = styled.div`
  > div > div > p {
    ${breakpoint("sm")} {
      width: ${rem(64)};
    }
    ${breakpoint("lg")} {
      width: unset;
    }
  }
`;

export const TopTextWrapper = styled.div`
  margin-top: ${rem(16)};
  p {
    text-align: center;
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${rem(32)};
  margin-inline: 0;
  padding: 0 ${rem(16)};

  ${breakpoint("md")} {
  }
`;

export const BottomTextWrapper = styled.div`
  div > div {
    text-align: center;
  }
`;

export const BottomColumn = styled.div`
  padding: 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  justify-content: center;
  width: 100%;
  padding: 0 ${rem(32)};
  margin: ${rem(16)} 0;

  ${breakpoint("md")} {
    padding: 0 ${rem(32)};
    margin: ${rem(32)} auto;
    width: 75%;
    align-content: center;
  }
`;
