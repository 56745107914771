import useTranslation from "next-translate/useTranslation";
import { Grid, Paragraph, ToolTip } from "@boxt/design-system";

import { ReactComponent as Info } from "@Images/icons/comparison-tooltip.svg";

import { Question } from "./styles";
import type { Question as QuestionType } from "./types";

type Props = {
  question: QuestionType;
  isButterComponent?: boolean;
  isLastRow: boolean;
  hasOneGroup: boolean;
  hoverBehaviour: "click" | "hover";
  hasTooltip: boolean;
  i18nNamespace?: string;
};

export const QuestionChild = ({
  question,
  isButterComponent = false,
  isLastRow,
  hasOneGroup,
  hoverBehaviour,
  hasTooltip,
  i18nNamespace,
}: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { question_text, tooltip } = question;
  return (
    <Grid.Col
      $hasTooltip={hasTooltip}
      $isLastRow={isLastRow}
      as={Question}
      lg={{ offset: hasOneGroup ? 3 : 2, span: 6 }}
      md={{ offset: hasOneGroup ? 1 : 0, span: 4 }}
      role="rowheader"
      sm={2}
    >
      <Paragraph align="left" boxtTheme="slate" weight="bold">
        {isButterComponent ? t("butter-comparison-table", { question_text }) : question_text}
      </Paragraph>
      {tooltip && (
        <ToolTip toolTipText={tooltip} triggerAction={hoverBehaviour}>
          <Info />
        </ToolTip>
      )}
    </Grid.Col>
  );
};
