import styled from "styled-components";
import { breakpoint, colors, rem } from "@boxt/design-system";

export const Container = styled.div`
  border-radius: 16px;
  background-color: ${colors.acidGreen.lighter};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${rem(16)};
  margin: ${rem(16)} ${rem(16)} ${rem(24)};
  max-width: ${rem(1264)};

  ${breakpoint("md")} {
    flex-direction: row;
    padding: ${rem(16)} ${rem(32)};
  }

  ${breakpoint("lg")} {
    padding: ${rem(24)} ${rem(32)};
    margin: 0 auto ${rem(24)};
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0 ${rem(24)} ${rem(16)} 0;

  ${breakpoint("md")} {
    margin: 0 ${rem(36)} 0 0;
  }

  ${breakpoint("lg")} {
    justify-content: center;
    margin: 0 ${rem(48)} 0 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-content: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${rem(8)};
  flex-wrap: wrap;

  ${breakpoint("md")} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${breakpoint("lg")} {
    justify-content: space-evenly;
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-self: flex-start;
  gap: ${rem(8)};

  ${breakpoint("md")} {
    align-self: center;
  }
`;
