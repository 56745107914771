import type { MutableRefObject } from "react";
import { forwardRef, useEffect, useRef } from "react";
import type { Interpolation } from "styled-components";

import { Container, mapStyle } from "./styles";

type Props = {
  customMapWrapper?: Interpolation<object>;
  customMapStyles?: google.maps.MapTypeStyle[];
  options: google.maps.MapOptions;
  onBoundsSet?: () => void;
  onMapClick?: (location: google.maps.LatLng) => void;
};

const GoogleMap = ({ customMapStyles, options, onBoundsSet, onMapClick, customMapWrapper }: Props, ref) => {
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const map = new google.maps.Map(mapRef.current as HTMLDivElement, {
      ...options,
      styles: customMapStyles ?? mapStyle,
      tilt: 0,
    });

    map.addListener("click", (event) => {
      onMapClick?.(event.latLng);
    });

    (ref as MutableRefObject<google.maps.Map>).current = map;

    if (onBoundsSet) {
      google.maps.event.addListenerOnce(map, "bounds_changed", () => {
        onBoundsSet();
      });
    }

    // this useEffect should be executed once, like componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Container $css={customMapWrapper} ref={mapRef} />;
};

export default forwardRef<google.maps.Map, Props>(GoogleMap);
