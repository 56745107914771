import type { EventArgs } from "react-ga";
import { isEmpty } from "lodash-es";
import { Button, type ButtonTheme, Spacing } from "@boxt/design-system";

import { ReactGAEvent } from "@Lib/googleAnalytics";
import { ButtonsWrapper } from "@Components/pages/common/LandingWhySection/ButtonsSection/styles";
import ScrollToButton from "@Components/pages/common/ScrollToButton";

interface Props {
  secondaryCtaText: string;
  secondaryCtaTheme: ButtonTheme;
  secondaryCtaURL: string;
  mainCtaText: string;
  mainCtaTheme: ButtonTheme;
  gaScrollEvent?: EventArgs;
}

const ButtonsSection = ({
  secondaryCtaText,
  secondaryCtaTheme,
  secondaryCtaURL,
  mainCtaText,
  mainCtaTheme,
  gaScrollEvent,
}: Props) => (
  <ButtonsWrapper>
    <ScrollToButton
      boxtTheme={mainCtaTheme}
      onClick={() => gaScrollEvent && ReactGAEvent(gaScrollEvent)}
      skin="primary"
      titleText={mainCtaText}
    />
    <Spacing mb={2} />
    {!isEmpty(secondaryCtaText) && (
      <Button
        boxtTheme={secondaryCtaTheme}
        href={secondaryCtaURL}
        rel="noopener noreferrer"
        skin="primary"
        target="_blank"
      >
        {secondaryCtaText}
      </Button>
    )}
  </ButtonsWrapper>
);

export default ButtonsSection;
