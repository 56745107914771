import styled from "styled-components";
import { breakpoint, rem } from "@boxt/design-system";

export const Container = styled.div`
  display: block;

  ${breakpoint("lg")} {
    display: grid;
    gap: ${rem(8)};
    grid-template-columns: repeat(3, 1fr);
  }
`;
