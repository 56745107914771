import { useEffect, useState } from "react";

import { CTAButton } from "@Components/pages/common/PrivacyCentreCTA/styles";
import type { ButterPrivacyCentreCTAFields } from "@Components/pages/common/PrivacyCentreCTA/types";

type Props = {
  fields: ButterPrivacyCentreCTAFields;
};

const PrivacyCentreCTA = ({ fields }: Props) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  useEffect(() => {
    // Check if the script has already loaded
    if (window.sp && typeof window.sp.showPrivacyBanner === "function") {
      setIsScriptLoaded(true);
    } else {
      // Otherwise, wait for the script to load
      const interval = setInterval(() => {
        if (window.sp && typeof window.sp.showPrivacyBanner === "function") {
          setIsScriptLoaded(true);
          clearInterval(interval);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, []);

  const handleClick = () => {
    if (isScriptLoaded && !!window.sp?.cookieList) {
      window.sp.showPrivacyBanner();
    }
  };

  return (
    <CTAButton
      boxtTheme={fields.button_theme.theme || "coral"}
      data-testid="privacy-centre-cta-button"
      onClick={handleClick}
      size="large"
    >
      {fields.cta_text}
    </CTAButton>
  );
};

export default PrivacyCentreCTA;
