import styled from "styled-components";
import { breakpoint, Button, colors, Grid, includeSpacing, rem, Spacing } from "@boxt/design-system";

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;

  ${breakpoint("lg")} {
    padding: ${rem(40)};
    border-radius: ${rem(20)} ${rem(20)} 0 0;
    margin-top: ${rem(100)};
    background-color: ${colors.white};
  }
`;

export const ModulesDiv = styled.div`
  padding: ${rem(40)} 0 0 0;

  ${breakpoint("md")} {
    padding: ${rem(40)} ${rem(32)} 0 ${rem(32)};
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
  }
`;

export const SliderRow = styled(Grid.Row)`
  display: flex;
  justify-content: center;
  margin: 0;
`;

export const StyledRow = styled(Grid.Row)`
  margin: 0;
  background-color: ${colors.white};
`;

export const CardContainer = styled.div`
  padding: ${rem(24)} ${rem(16)} 0 ${rem(16)};
  width: 100%;
  height: 100%;
  border-radius: ${rem(20)} ${rem(20)} 0 0;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  ${breakpoint("lg")} {
    padding: 0;
    max-width: ${rem(420)};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${breakpoint("lg")} {
    flex-direction: row;
  }
`;

export const CardContent = styled(Spacing)`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${includeSpacing({ mb: 4, mh: { lg: 3, sm: 2 }, mt: 3 })}
`;

export const LogosWrapper = styled(Spacing)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PayText = styled.h5`
  color: ${colors.slate.light};
`;

export const FixedPrice = styled.h5`
  color: ${colors.slate.normal};
  font-weight: normal;
`;

export const TagWrapper = styled(Spacing)`
  display: flex;
  flex-wrap: wrap;
  ${breakpoint("md")} {
    align-self: baseline;
  }
`;

export const FinanceButton = styled(Button)`
  font-weight: bold;
`;

export const ProductImageCol = styled(Grid.Col)`
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${includeSpacing({ mt: { lg: 0, md: 6, sm: 6 } })};
`;

export const CardCol = styled(Grid.Col)`
  padding: 0;
`;

export const RecommendedSizesWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ViewSpecsWrapper = styled.div`
  margin: ${rem(16)} 0 ${rem(24)};
`;

export const ViewSpecsButton = styled(Button)`
  font-weight: bold;
`;

export const PriceLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

export const Wrapper = styled.div`
  background-color: ${colors.white};
  padding: ${rem(24)};
  border-radius: ${rem(8)};
`;
