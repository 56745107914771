import styled from "styled-components";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

export const StyledInput = styled.input`
  position: absolute;
  padding: 0;
  margin: 0;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;
`;

export const StyledSpan = styled.span`
  display: block;
`;

export const IconContainer = styled.span<{ $isSelected: boolean }>`
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  width: ${rem(30)};
  height: ${rem(30)};
  border: 2px solid;
  border-color: ${({ $isSelected }) => ($isSelected ? colors.jade.normal : colors.slate.lighter)};
  border-radius: 50%;
  background-color: ${({ $isSelected }) => ($isSelected ? colors.jade.normal : colors.white)};
  color: ${colors.white};
  padding: ${rem(4)};

  ${breakpoint("lg")} {
    margin-right: auto;
  }
`;

export const BackgroundContainer = styled.label<{ $isSelected: boolean; $isLoading: boolean }>`
  background-color: ${colors.white};
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
  border: 1px solid;
  border-color: ${({ $isSelected }) => ($isSelected ? colors.jade.normal : colors.white)};
  cursor: ${({ $isLoading }) => ($isLoading ? "not-allowed" : "pointer")};
  width: 100%;
  text-align: left;
  display: block;

  &:hover {
    border-color: ${colors.jade.normal};

    ${IconContainer} {
      border-color: ${colors.jade.normal};
      background-color: ${({ $isSelected }) => ($isSelected ? colors.jade.normal : colors.white)};
      color: ${({ $isSelected }) => ($isSelected ? colors.white : colors.jade.normal)};
    }
  }

  &:active {
    ${IconContainer} {
      background-color: ${colors.jade.normal};
      color: ${colors.white};
    }
  }

  ${breakpoint("lg")} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

export const Container = styled.span`
  align-items: center;
  display: flex;

  ${breakpoint("lg")} {
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
  }
`;

export const ImageContainer = styled.span`
  flex-shrink: 0;
  height: ${rem(100)};
  ${includeSpacing({ mb: { lg: 1, sm: 0 }, mr: { lg: 0, sm: 2 } })};
`;

export const CopyContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  flex: 1 1 auto;

  ${breakpoint("xlg")} {
    flex-direction: row;
  }
`;

export const LabelInnerContainer = styled.span`
  display: block;

  ${breakpoint("lg")} {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
`;

export const LabelSpacing = styled.span`
  display: block;
  ${includeSpacing({ mh: { lg: 2 }, mv: { lg: 2, sm: 1 } })}

  ${breakpoint("lg")} {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
  }
`;

export const TopRowContainer = styled.span`
  display: flex;
  align-items: center;
  ${breakpoint("lg")} {
    flex-direction: column;
  }
`;
