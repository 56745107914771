import Lottie from "react-lottie-player";

import { Container } from "./styles";

type Props = {
  animation?: string;
};

const BackgroundAnimation = ({ animation }: Props) => {
  return (
    <Container data-testid="landing-hero-background-animation">
      <Lottie
        loop
        path={animation}
        play
        rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
        style={{ height: "100%" }}
      />
    </Container>
  );
};

export default BackgroundAnimation;
