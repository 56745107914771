import dynamic from "next/dynamic";

import { TrustPilotWidgetType } from "@Components/pages/common/LandingTrustpilot/types";

import { TrustpilotWrapper } from "./styles";

const Trustpilot = dynamic(() => import("@Components/pages/common/Trustpilot"), {
  ssr: false,
});

type Props = {
  widget: TrustPilotWidgetType | null;
  idAttribute: string;
};

const LandingTrustpilot = ({ widget, idAttribute }: Props) => {
  if (widget === TrustPilotWidgetType.summary) {
    return (
      <TrustpilotWrapper id={idAttribute}>
        <Trustpilot />
      </TrustpilotWrapper>
    );
  }
  return <TrustpilotWrapper />;
};

export default LandingTrustpilot;
