import type { ElementType } from "react";
import { useState } from "react";
import type { AnimatedComponent } from "@react-spring/web";
import { animated, config, useInView } from "@react-spring/web";

import { StyledH2 } from "../styles";

type Props = {
  amount: string;
  isAnimated: boolean;
};

const AnimatedNumberComponent = ({ amount, isAnimated }: Props) => {
  const AnimatedNumberDiv: AnimatedComponent<ElementType> = animated(StyledH2);
  const [reactSpringNum, setReactSpringNum] = useState(0);
  const letters = amount.match(/\D+/g);
  const number = Number(amount.match(/\d+/g));

  const [ref, setY] = useInView(() => ({
    config: config.slow,
    from: { num: 0 },
    immediate: false,
    onChange: (props) => {
      const {
        value: { num },
      } = props;
      setReactSpringNum(Math.floor(num));
    },
    to: { num: number },
  }));
  return (
    <>
      {isAnimated ? (
        <div ref={ref || null}>
          <AnimatedNumberDiv style={(ref && setY) || null}>
            {reactSpringNum.toLocaleString()}
            {letters}
          </AnimatedNumberDiv>
        </div>
      ) : (
        <StyledH2>
          {number}
          {letters}
        </StyledH2>
      )}
    </>
  );
};

export default AnimatedNumberComponent;
