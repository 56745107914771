import styled from "styled-components";
import { animated } from "@react-spring/web";
import { breakpoint, colors, Paragraph, rem } from "@boxt/design-system";

import withCustomWrapper from "@Helpers/withCustomWrapper";

export const HeadingContainer = styled.div`
  display: none;
  ${breakpoint("md")} {
    display: block;
  }
`;

export const SubheadingContainer = styled.div`
  display: none;
  ${breakpoint("lg")} {
    display: block;
  }
`;

export const Subheading = withCustomWrapper(
  ({ children }: { children: string }) => (
    <Paragraph bottom={{ lg: 4, sm: 0 }} boxtTheme="slateLight" size="normal">
      {children}
    </Paragraph>
  ),
  ({ children }: { children: string }) => (
    <Paragraph as="strong" boxtTheme="slate" size="normal" weight="bold">
      {children}
    </Paragraph>
  ),
);

export const ButtonText = styled.span`
  white-space: nowrap;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${rem(16)};
  justify-content: flex-start;
  max-width: ${rem(280)};
  min-height: ${rem(90)};
  width: 100%;

  ${breakpoint("md")} {
    justify-content: space-between;
    max-width: none;
  }
`;

export const AnimatedContainer = styled(animated.div)`
  background-color: ${colors.white};
  bottom: 0;
  box-shadow: 0 0 15px 0 #33455726;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 10;

  ${breakpoint("md")} {
    padding: 0 ${rem(90)} 0 ${rem(32)};
  }
  ${breakpoint("lg")} {
    padding: 0 ${rem(90)};
  }
`;
