import useTranslation from "next-translate/useTranslation";
import { kebabCase } from "lodash-es";
import { H5, Paragraph, Spacing } from "@boxt/design-system";

import type { BatterySize } from "@DataAccess/remote/solar";
import Image from "@Components/Image";
import { ReactComponent as TickIcon } from "@Images/icons/small-tick.svg";

import {
  BackgroundContainer,
  Container,
  CopyContainer,
  IconContainer,
  ImageContainer,
  LabelInnerContainer,
  LabelSpacing,
  StyledInput,
  StyledSpan,
  TopRowContainer,
} from "./styles";

type Props = {
  onChange: () => void;
  isLoading: boolean;
  batterySize: BatterySize;
  id: string;
  name: string;
  isSelected: boolean;
  imageUrl: string;
  title: string | null;
};

const BatteryOption = ({ batterySize, onChange, isLoading, id, name, isSelected, imageUrl, title }: Props) => {
  const { t } = useTranslation("solar/savings-calculator");

  return (
    <BackgroundContainer $isLoading={isLoading} $isSelected={isSelected} aria-checked={isSelected} htmlFor={id}>
      <StyledInput
        checked={isSelected}
        disabled={isLoading}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
        value={batterySize}
      />
      <LabelSpacing>
        <LabelInnerContainer>
          <Container>
            <TopRowContainer>
              {imageUrl ? (
                <ImageContainer>
                  <Image alt={name} height={100} objectFit="cover" src={imageUrl} width={55} />
                </ImageContainer>
              ) : null}
              <CopyContainer>
                <Spacing as={StyledSpan} mr={{ lg: 0, sm: 2 }} mv={{ sm: 0, xlg: 2 }}>
                  <H5 as={StyledSpan} bottom={{ lg: 3, sm: 2 }}>
                    {title}
                  </H5>
                  <Paragraph as={StyledSpan} bottom={{ lg: 5, md: 0 }} boxtTheme="slate">
                    {t(`battery.widget.${kebabCase(batterySize)}.explanation`)}
                  </Paragraph>
                </Spacing>
              </CopyContainer>
            </TopRowContainer>
            <IconContainer $isSelected={isSelected}>
              <TickIcon />
            </IconContainer>
          </Container>
        </LabelInnerContainer>
      </LabelSpacing>
    </BackgroundContainer>
  );
};

export default BatteryOption;
