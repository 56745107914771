import Image from "@Components/Image";

import type { ButterLandingHeroBackgroundImage } from "../../types";

import { DesktopImage, MobileImage, TabletImage } from "./styles";

type Props = {
  backgroundImage: ButterLandingHeroBackgroundImage;
};

const BackgroundImage = ({ backgroundImage }: Props) => {
  return (
    <>
      <MobileImage>
        <Image
          alt=""
          data-testid="landing-hero-background-image"
          layout="fill"
          objectFit="cover"
          priority
          src={backgroundImage.mobile as string}
        />
      </MobileImage>
      <TabletImage>
        <Image
          alt=""
          data-testid="landing-hero-background-image"
          layout="fill"
          objectFit="cover"
          priority
          src={backgroundImage.tablet as string}
        />
      </TabletImage>
      <DesktopImage>
        <Image
          alt=""
          data-testid="landing-hero-background-image"
          layout="fill"
          objectFit="cover"
          priority
          src={backgroundImage.desktop as string}
        />
      </DesktopImage>
    </>
  );
};

export default BackgroundImage;
